import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";

import "./App.css";

// import WorkingOrders from "./Pages/WorkingOrders";
// import JobsWithoutPdf from "./Pages/JobsWithoutPdf";
// import Login from "./Pages/Login";
// import PlannedInstallations from "./Pages/PlannedInstallations";
// import Instructions from "./Pages/Instructions";
// import Instruction from "./Pages/Instruction";

const Login = lazy(() => import("./Pages/Login"));
const WorkingOrders = lazy(() => import("./Pages/WorkingOrders"));
const JobsWithoutPdf = lazy(() => import("./Pages/JobsWithoutPdf"));
const PlannedInstallations = lazy(() => import("./Pages/PlannedInstallations"));
const Instructions = lazy(() => import("./Pages/Instructions"));
const Instruction = lazy(() => import("./Pages/Instruction"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/workingOrders" element={<WorkingOrders />} />
          <Route path="/jobsWithoutPdf" element={<JobsWithoutPdf />} />
          <Route
            path="/plannedInstallations"
            element={<PlannedInstallations />}
          />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/instruction/:id" element={<Instruction />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
